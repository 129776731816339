import React from 'react';
import Master from '../Master';

function DashBoard(props) {
    return (
        <Master title="DashBoard">
            Dashboard
        </Master>
    );
}

export default DashBoard;