import React from 'react';
import Master from '../Master';

function HomeView(props) {
    return (
        <Master>
            Please Login
        </Master>
    );
}

export default HomeView;