import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function LoginFail(props) {
    return (
        <>
            <Helmet>
                <title>Not Found or You do not have permission.</title>
            </Helmet>
            <Container fluid className='h-100 d-flex align-items-center justify-content-center'>
                <Row>
                    <h3>Not Found or You do not have permission.</h3>
                </Row>
            </Container>
        </>

    );
}

export default LoginFail;