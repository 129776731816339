import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { account } from '../config/appwrite';
import appStore from '../store/appStore';
import { Helmet } from 'react-helmet-async';

function Master(props) {
    const { children, title } = props;
    const auth = useRecoilValue(appStore)
    const url = window.location.href;

    const loginBtn = async () => {
        return await account.createOAuth2Session("google", `${url}`, `${url}loginFailed`);
    }

    const logoutBtn = async () => {
        return await account.deleteSession('current').then(
            () => window.location.reload(), err => console.log(err)
        );
    }

    return (
        <>
            <Helmet>
                <title>{(title) ? `${title} ` : `Hello World`}</title>
            </Helmet>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand as={Link} to="/">CMS</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                        {(auth) ? (
                            <>
                                <Nav className="me-auto">
                                    <Nav.Link as={Link} to="/categories">Categories</Nav.Link>
                                    <Nav.Link as={Link} to="/posts">Post</Nav.Link>
                                </Nav>
                                <Nav >
                                    <Nav.Link onClick={logoutBtn}>Logout</Nav.Link>
                                </Nav>
                            </>
                        ) : (
                            <>
                                <Nav >
                                    <Nav.Link onClick={loginBtn}>Login</Nav.Link>
                                </Nav>
                            </>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                {children}
            </Container>
        </>
    );
}

export default Master;