import { ID, Query } from "appwrite";
import { CATEGORIES_ID, DATABASE_ID, POST_ID, databases } from "./appwrite";

export const listPost = (limit = 20, offset = 0) => databases.listDocuments(DATABASE_ID, POST_ID, [
    Query.limit(limit),
    Query.offset(offset)
]);

export const listCategories = () => databases.listDocuments(DATABASE_ID, CATEGORIES_ID, [])

export const createCategory = async (data) => await databases.createDocument(DATABASE_ID, CATEGORIES_ID, ID.unique(), data)
export const deleteCategory = async (id) => await databases.deleteDocument(DATABASE_ID, CATEGORIES_ID, id)