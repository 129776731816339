import React from 'react';
import Master from '../Master';

function AuthView(props) {
    return (
        <Master>
            heheh
        </Master>
    );
}

export default AuthView;