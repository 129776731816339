import React, { useEffect, useState } from 'react';
import { createCategory, deleteCategory, listCategories } from '../../config/database';
import Master from '../Master';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

function Categories(props) {
    const [list, setList] = useState([])
    const [name, setName] = useState('')

    useEffect(() => {
        loadList()
    }, [])

    const loadList = async () => {
        await listCategories().then(rs => { setList(rs.documents); console.log(rs) }, err => console.log(err));
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        await createCategory({ name: name }).then(rs => console.log(rs), err => console.log(err))
        await loadList()
    }

    const onDel = async (id) => {
        await deleteCategory(id).then(rs => console.log(rs), err => console.log(err))
        await loadList()
    }

    return (
        <Master title="Categories">
            Categories
            <Row className='justify-content-md-center'>
                <Col md="4">
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="name" required value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" >
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>

            <Row className='mt-4'>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td><Button variant="danger" size="sm" onClick={() => onDel(item.$id)}>Delete</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>

        </Master>
    );
}

export default Categories;