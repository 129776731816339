import { Account, Client, Databases } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('66554bd2000ec6406c27');

const account = new Account(client);
const databases = new Databases(client);

const DATABASE_ID = '665597bd002761d5707d'
const COLLECTION_ID = '665597e60026d2fcc0a9'
const CATEGORIES_ID = '665597e60026d2fcc0a9'
const POST_ID = '66559987003a2d07b534'

export { account, databases, DATABASE_ID, CATEGORIES_ID, POST_ID, COLLECTION_ID, }
export { ID } from 'appwrite'