import React, { useEffect, useState } from 'react';
import { listCategories, listPost } from '../../config/database';
import Master from '../Master';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

function Posts(props) {
    const [list, setList] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        loadList();
        listCategories().then(rs => { setCategories(rs.documents); console.log(rs.documents); }, err => console.log(err));
    }, [])

    const loadList = async () => {
        await listPost().then(rs => setList(rs.documents), err => console.log(err));
    }

    return (
        <Master title="Posts">
            <Row className='justify-content-md-center'>
                <h3>Post</h3>
                <Col md="8">
                    <Form>
                        <Form.Group className="mb-3" controlId="categories">
                            <Form.Select >
                                {categories.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="content">
                            <Form.Label>Content</Form.Label>
                            <Form.Control as="textarea" rows={5} />
                        </Form.Group>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Form>

                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Content</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.content}</td>
                                    <td>{item.category}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Master>
    );
}

export default Posts;