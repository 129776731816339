import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useRecoilState } from 'recoil';
import appStore from '../store/appStore';
import { account } from '../config/appwrite';
import { Categories, DashBoard, HomeView, LoginFail, Posts } from '../view';

const RouterGuide = () => (
  <Routes >
    <Route index element={<HomeView />} />
    <Route path="/loginfail" element={<LoginFail />} />
    <Route path="*" element={<LoginFail />} />
  </Routes>
)

const RouterAuth = () => (
  <Routes >
    <Route index element={<DashBoard />} />
    <Route path='categories' element={<Categories />} />
    <Route path='posts' element={<Posts />} />
    <Route path="*" element={<LoginFail />} />
  </Routes>
)

function AppRouter(props) {
  const [auth, setAuth] = useRecoilState(appStore);

  useEffect(() => {
    const checkAuth = async () => {
      account.get().then(
        () => setAuth(true), setAuth(false)
      )
    }
    checkAuth()
  }, []);

  return (
    <BrowserRouter>
      {(auth) ? <RouterAuth /> : <RouterGuide />}
    </BrowserRouter>
  )
}

export default AppRouter;
